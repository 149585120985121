.Loader {
  display: flex;
  align-items: center;
  z-index: 11;
  height: 24px;
  width: 24px;
  position: absolute;
  pointer-events: none;
  top: calc(50% + 18px);
  left: 50%;
}
